@font-face {
  font-family: 'BCSans';
  font-style: normal;
  src: url('../../node_modules/@bcgov/bc-sans/fonts/BCSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'BCSans-Italic';
  font-style: italic;
  src: url('../../node_modules/@bcgov/bc-sans/fonts/BCSans-Italic.woff') format('woff');
}
@font-face {
  font-family: 'BCSans-Bold';
  font-weight: 700;
  src: url('../../node_modules/@bcgov/bc-sans/fonts/BCSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'BCSans-BoldItalic';
  font-style: italic;
  font-weight: 700;
  src: url('../../node_modules/@bcgov/bc-sans/fonts/BCSans-BoldItalic.woff') format('woff');
}
