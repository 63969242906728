.leaflet-draw.leaflet-control {
  transform: scale(1.4) translate(0, 28px);
}

.leaflet-control-zoom.leaflet-control {
  transform: scale(1.4) translate(0, 5px);
}

.leaflet-control-locate.leaflet-control {
  transform: scale(1.4);
}

.savetiles.leaflet-control {
  transform: scale(1.4) translate(0, -5px);
}

.leaflet-control-locate.leaflet-control .bullseye {
  background-image: url('/assets/icon/location-button.svg');
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  transform: translate(-24px, -18px) scale(0.4);
}
